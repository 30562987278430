'use client';

import clsx from 'clsx';
import Image from 'next/image';
import { useEffect, useState } from 'react';

import { ImageType } from '@/types/Image';

import { Button } from '../button/Button.component';
import { TextBlock } from '../text-block/TextBlock.component';

import styles from './Hero.module.scss';

type HeroProps = {
    strings: {
        kicker?: string;
        title?: string;
        subtitle?: string;
        body?: string;
    };
    image: ImageType;
    joinText?: string;
};

export const Hero = ({ strings, image, joinText }: HeroProps) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const onPageLoad = () => {
            setLoaded(true);
        };
        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad);
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);

    return (
        <div className={clsx(styles.heroSection, { [styles.loaded]: loaded })}>
            <Image className={styles.background} alt="" src={image?.src} fill priority />
            <div className={styles.cover} />
            <div className={styles.copy}>
                {strings.kicker && <h6>{strings.kicker}</h6>}
                {strings.title && <h1>{strings.title}</h1>}
                {strings.subtitle && (
                    <h2>
                        <TextBlock content={strings.subtitle} />
                    </h2>
                )}
                {strings.body && <TextBlock content={strings.body} />}
                {joinText && (
                    <Button href="#subscribe" extraClass={styles.joinBtn}>
                        {joinText}
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z" />
                        </svg>
                    </Button>
                )}
            </div>
        </div>
    );
};
