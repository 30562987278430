'use client';

import clsx from 'clsx';
import { PropsWithChildren, useEffect } from 'react';
import { FieldValues, FormProvider, useForm, UseFormProps } from 'react-hook-form';

type FormProps = PropsWithChildren<{
    submitCallback: (data: any) => void;
    successMessage?: string;
    errorMessage?: string;
    initialValues?: FieldValues;
    resetCallback?: () => void;
    className?: string;
    errorClassName?: string;
    successClassName?: string;
    formOptions?: UseFormProps;
}>;

export const Form = ({
    children,
    successMessage,
    errorMessage,
    initialValues,
    submitCallback,
    resetCallback,
    className,
    errorClassName,
    successClassName,
    formOptions,
}: FormProps) => {
    const useFormMethods = useForm({ defaultValues: { ...initialValues }, ...formOptions });
    const {
        handleSubmit,
        clearErrors,
        reset,
        formState: { isSubmitSuccessful, isSubmitted },
    } = useFormMethods;

    const onSubmit = async (data: any) => {
        await submitCallback(data);
    };

    const onReset = () => {
        clearErrors();
        reset();
        resetCallback && resetCallback();
    };

    useEffect(() => {
        if (isSubmitSuccessful) {
            setTimeout(() => reset(), 2500);
        }
    }, [reset, isSubmitSuccessful]);

    return (
        <FormProvider {...useFormMethods}>
            <form
                onSubmit={handleSubmit(onSubmit)}
                onReset={onReset}
                className={clsx('amt-form', className)}
            >
                {isSubmitSuccessful && successMessage ? (
                    <div
                        className={clsx('amt-status--success', successClassName)}
                        dangerouslySetInnerHTML={{ __html: successMessage }}
                    />
                ) : isSubmitted && errorMessage ? (
                    <div
                        className={clsx('amt-status--success', errorClassName)}
                        dangerouslySetInnerHTML={{ __html: errorMessage }}
                    />
                ) : null}
                {children}
            </form>
        </FormProvider>
    );
};
