'use client';

import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx';
import { useId } from 'react';
import { FieldValues, RegisterOptions, useFormContext } from 'react-hook-form';

interface InputFieldType extends React.InputHTMLAttributes<HTMLInputElement> {
    label: string;
    name: string;
    inputProps?: RegisterOptions<FieldValues, string>;
    type?: string;
    className?: string;
    errorClassName?: string;
}

export const InputField = ({
    label,
    name,
    inputProps,
    type,
    className,
    errorClassName,
    ...props
}: InputFieldType) => {
    const id = useId();

    const {
        register,
        formState: { errors },
    } = useFormContext();

    const hasInlineMax =
        ['date', 'datetime-local', 'number'].includes(type || '') &&
        Object.hasOwn(inputProps as any, 'max');

    const hasInlineMin =
        ['date', 'datetime-local', 'number'].includes(type || '') &&
        Object.hasOwn(inputProps as any, 'min');

    return (
        <div className={clsx('amt-field', className)}>
            <label htmlFor={id} className="amt-label">
                <span dangerouslySetInnerHTML={{ __html: label }} />
                <input
                    type={type ? type : 'text'}
                    id={id}
                    className="amt-input"
                    {...register(name, { ...inputProps })}
                    {...(hasInlineMax && { max: (inputProps as any).max })}
                    {...(hasInlineMin && { min: (inputProps as any).min })}
                    {...props}
                />
            </label>
            <div className={errorClassName}>
                <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => (
                        <label htmlFor={id} className="amt-error">
                            {message}
                        </label>
                    )}
                />
            </div>
        </div>
    );
};
