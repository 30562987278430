'use client';

import clsx from 'clsx';
import Image from 'next/image';
import { useParams } from 'next/navigation';
import { useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { Button } from '@/components/button/Button.component';
import { TextBlock } from '@/components/text-block/TextBlock.component';
import { Form } from '@/features/forms/components/form/Form.component';
import { InputField } from '@/features/forms/components/input-field/InputField.component';
import { useGeolocation } from '@/features/geolocation/stores/geolocation.store';
import { useInView } from '@/hooks/useInView';
import { ImageType } from '@/types/Image';

import { submitSignup } from '../../api/submit-signup';

import styles from './NewsletterForm.module.scss';

type NewsletterFormProps = {
    strings: {
        email_label: string;
        email_error: string;
        dob_label: string;
        dob_error: string;
        privacy_label: any;
        privacy_link: string;
        privacy_error: string;
        tos_label: any;
        tos_link: string;
        tos_error: string;
        submit: string;
        submission_success_message: string;
        submission_error_message: string;
        title: string;
        subtitle: string;
        opening: any;
    };
    className?: string;
    image?: ImageType;
    language: string;
};

const replaceWithLink = (string: string, href: string, required?: string, lang?: string) => {
    let linkText = string?.replace(/\{\{(.*?)\}\}/, (_, p1) => {
        if (lang === 'ko-kr') {
            return `<a href=${href} target="_blank" rel="nofollow noopener noreferrer"><strong>${p1}</strong></a>`;
        }
        return `<a href=${href} target="_blank" rel="nofollow noopener noreferrer">${p1}</a>`;
    });

    if (required) {
        linkText += `<span>(${required})</span>`;
    }

    return linkText;
};

export const NewsletterForm = ({ strings, className, image, language }: NewsletterFormProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const { inView } = useInView({ ref: containerRef, onlyOnce: true });

    const minAge = new Date(new Date().setFullYear(new Date().getFullYear() - 18));

    const today = useMemo(() => {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const year = today.getFullYear();
        return `${year}-${month}-${day}`;
    }, []);

    const urlParams = useParams();
    const inferredLanguage = ((urlParams.lang as string) || 'en').split('-').at(0);

    const country = useGeolocation((state) => state.country_code);

    const validateDoB = (date: string) => {
        const dateAsDate = new Date(date || '');
        const isOldEnough = dateAsDate < minAge;
        return isOldEnough;
    };

    const handleSubmit = (data: any) => {
        Object.assign(data, { language: inferredLanguage || 'en', country: country || 'us' });
        return submitSignup(data);
    };

    return (
        <section
            className={clsx(styles.amtNewsletterSignupContainer, { [styles.animate]: inView })}
            ref={containerRef}
            id="subscribe"
        >
            <div className={styles.textWrapper}>
                <h6>{strings.subtitle}</h6>
                <h3>{strings.title}</h3>
                <TextBlock content={strings.opening} className={styles.introParagraph} />
                <Form
                    className={clsx('amt-newsletter-signup', styles.form, className)}
                    submitCallback={handleSubmit}
                    successMessage={strings.submission_success_message}
                    errorMessage={strings.submission_error_message}
                    errorClassName={styles.errorState}
                    successClassName={styles.successState}
                    formOptions={{ mode: 'onChange' }}
                >
                    <InputField
                        label={strings.dob_label}
                        type="date"
                        name="dob"
                        inputProps={{
                            required: {
                                value: true,
                                message: strings.dob_error,
                            },
                            min: {
                                value: '1900-01-01',
                                message: strings.dob_error,
                            },
                            max: {
                                value: today,
                                message: strings.dob_error,
                            },
                            validate: (value: string) => validateDoB(value) || strings.dob_error,
                        }}
                        className={styles.newsletterInput}
                        errorClassName={styles.errorMessage}
                    />
                    <InputField
                        type="checkbox"
                        label={replaceWithLink(
                            strings.privacy_label,
                            strings.privacy_link,
                            strings.privacy_error,
                            language
                        )}
                        name="privacy"
                        inputProps={{ required: strings.privacy_error }}
                        className={styles.newsletterCheck}
                        errorClassName={styles.errorMessage}
                    />
                    <InputField
                        type="checkbox"
                        label={replaceWithLink(
                            strings.tos_label,
                            strings.tos_link,
                            strings.tos_error
                        )}
                        name="tos"
                        inputProps={{ required: strings.tos_error }}
                        className={styles.newsletterCheck}
                        errorClassName={styles.errorMessage}
                    />
                    <div className={styles.submitEmail}>
                        <InputField
                            label={strings.email_label}
                            name="email"
                            type="email"
                            inputProps={{
                                required: strings.email_error,
                                pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: strings.email_error,
                                },
                            }}
                            className={styles.newsletterInput}
                            placeholder=" "
                            errorClassName={styles.errorMessage}
                        />
                        <Footer
                            submit={strings.submit}
                            submitting={strings.submit}
                            submitted={strings.submit}
                        />
                    </div>
                </Form>
            </div>
            {image && (
                <Image
                    className={styles.signUpImage}
                    alt=""
                    src={image?.src}
                    width={image?.width}
                    height={image?.height}
                />
            )}
        </section>
    );
};

type FooterProps = {
    submit: string;
    submitting: string;
    submitted: string;
};

const Footer = ({ submit, submitting, submitted }: FooterProps) => {
    const {
        formState: { isSubmitting, isSubmitSuccessful, isDirty, isValid },
    } = useFormContext();

    return (
        <footer>
            <Button
                type="submit"
                kind="secondary"
                disabled={isSubmitting || !isDirty || !isValid}
                className={styles.submitBtn}
            >
                {isSubmitting ? (
                    <>
                        <span className="amt-inline-spinner" />
                        {submitting}
                    </>
                ) : isSubmitSuccessful ? (
                    submitted
                ) : (
                    submit
                )}
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z" />
                </svg>
            </Button>
        </footer>
    );
};
