import { SITE_DOMAIN } from '@/constants';

export const submitSignup = async (fields: any) => {
    let data;

    try {
        const response = await fetch(`${SITE_DOMAIN}/api/newsletter/signup`, {
            method: 'POST',
            body: JSON.stringify(fields),
        });
        data = await response.json();
    } catch (error) {
        return error;
    }

    return data;
};
